import React from 'react'

import Carousel from 'react-bootstrap/Carousel'
import Linguica from '../assets/carrossel/linguica.png'
import Cachaca from '../assets/carrossel/cacha.png'
import Temperos from '../assets/carrossel/temperos.png'

import './inicio.estilo.scss'

export default function Inicio() {

   
    return (
        <div className='conteinerGeralInicio'>
            <div className='contCarrossel'>
                <Carousel fade>
                    <Carousel.Item>
                        <img src={Linguica} alt="" interval={3000} />                        
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={Cachaca} alt="" interval={3000} />                        
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={Temperos} alt="" interval={3000} />                       
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}
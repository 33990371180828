import React from 'react'
import './produtos.estilo.scss'

import Linguica from '../assets/linguica.png'
import Destilados from '../assets/destilados.png'
import Temperos from '../assets/temperos.png'

export default function Produtos() {
    return (       
            <div className='conteinerProdutosGeral'>
                <h1>Produtos Disponíveis</h1>
                <div className='conteinerImagensProdutos'>
                    <div className='cardProdutos'>
                        <p>Linguiças Artesanais</p>
                        <img src={Linguica} alt="" />
                    </div>
                    <div className='duplasImagensProdutos'>
                        <div className='cardProdutos'>
                            <p>Cachaças Artesanais</p>
                            <img src={Destilados} alt="" />
                        </div>
                        <div className='cardProdutos'>
                            <p>Temperos Artesanais</p>
                            <img src={Temperos} alt="" />
                        </div>
                    </div>
                </div>
            </div>
    )
}
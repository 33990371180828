import React from 'react'
import Manutencao from '../assets/manut.jpg'

import './login.estilo.scss'

export default function Login() {
    return (
        <div className='conteinerGeralInicio'>
            <h1>Login</h1>
            <img src={Manutencao} alt='' />
        </div>
    )
}
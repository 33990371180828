import React from 'react'
import { Link } from 'react-router-dom'
import './estilo.faixacabecalho.scss'

import Facebook from '../../assets/face.png'
import Instagram from '../../assets/insta.png'
import WhatsApp from '../../assets/whats.png'
import NomeSG from '../../assets/nomeSG.png'
import Loguinho from '../../assets/loguinho.png'


export default function FaixaCabecalho() {
    return (
        <section>
            <div className='conteinerGeralFaixaCabecalho'>               
                <div className='loguinhoEsquerda'>
                    <img src={Loguinho} alt="Loguinho" />
                </div>                
                <div className='nomeSG'>
                    <img src={NomeSG} alt="Nome SG" />
                </div>                
                <div className='redeSocial'>
                    <Link to='https://www.facebook.com/saboresdogauchobauru' target='_blank'><img src={Facebook} alt="Facebook" /></Link>
                    <Link to='https://www.instagram.com/sabores_do_gaucho?igsh=N2JrNW5hbDVlaTZ0' target='_blank'><img src={Instagram} alt="Instagram" /></Link>                    
                    <Link to='https://wa.me/5514981384515' target='_blank'><img src={WhatsApp} alt="WhatsApp" /></Link>
                    
                </div>
            </div>
        </section>
    )
}
import React from 'react'
import './estilo.cabecalhoN.scss'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export default function CabecalhoN() {
    return (
        <section>
            <div className='conteinerGeralCabecalho'>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/Inicio">Início</Nav.Link>
                                <Nav.Link href="/SobreNos">Sobre Nós</Nav.Link>                                
                                <Nav.Link href='/Produtos'>Produtos</Nav.Link>
                                <Nav.Link href='/LojaVirtual'>Loja Virtual</Nav.Link>
                                <Nav.Link href="/Login">Login</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </section>
    )
}
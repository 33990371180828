import { BrowserRouter, Routes, Route } from 'react-router-dom'

import FaixaCabecalho from '../Components/FaixaCabecalho'
import CabecalhoN from '../Components/CabecalhoN'
import Rodape from '../Components/Rodape'

import Inicio from '../Inicio'
import SobreNos from '../SobreNos'
import Produtos from '../Produtos'
import Login from '../Login'

export default function NaoAutenticados() {
    return (
        <BrowserRouter>
            <FaixaCabecalho />
            <CabecalhoN />
            <Routes>
                <Route path='/' element={<Inicio />} />
                <Route path='/SobreNos' element={<SobreNos />} />
                <Route path='/Produtos' element={<Produtos />} />
                <Route path='/Login' element={<Login />} />
                
                <Route path='/static' element={<Inicio />} />
                <Route path='*' element={<Inicio />} />
            </Routes>
            <Rodape />
        </BrowserRouter>
    )
}
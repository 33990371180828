import Rotas from './routes/'
import { ToastContainer } from 'react-toastify'

export default function App() {
  return (
    <div>
      <Rotas />
      <ToastContainer
        autoClose={5000}
      />
    </div>
  )
}
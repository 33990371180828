import React from 'react'

import NaoAutenticados from './naoAutenticado.routes'
import Autenticados from './autenticados.routes'


export default function Rotas(){

    const autenticado = false

    return(

        autenticado === true ? <Autenticados /> : <NaoAutenticados /> 
    )

}